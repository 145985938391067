<script setup lang="ts">
import {
  IconAffiliateFilled,
  IconAppWindowFilled,
  IconAspectRatioFilled,
  IconBellFilled,
  IconBookFilled,
  IconBrandDiscordFilled,
  IconCalendarFilled,
  IconChartPieFilled,
  IconFlag3Filled,
  IconInfoOctagonFilled,
  IconKeyframesFilled,
} from "@tabler/icons-vue"

const { myself } = useUser()
</script>

<template>
  <div class="w-56 flex-none border-r border-r-border-secondary bg-bg-secondary px-4 pt-6 space-y-0.5">
    <div class="section-header">
      Personal
    </div>
    <div class="account">
      <div class="h-8 w-8 rounded-full bg-blue-hex" />
      <div>
        <div>@user43862155</div>
        <div class="text-sm text-text-tertiary">
          {{ myself.user?.email }}
        </div>
      </div>
    </div>
    <settings-sidebar-item
      name="Storage"
      :icon="IconChartPieFilled"
    />
    <settings-sidebar-item
      name="Plans"
      :icon="IconKeyframesFilled"
    />
    <div class="section-header">
      System
    </div>
    <settings-sidebar-item
      name="Notifications"
      :icon="IconBellFilled"
    />
    <settings-sidebar-item
      name="Application"
      :icon="IconAppWindowFilled"
    />
    <settings-sidebar-item
      name="Integrations"
      :icon="IconAffiliateFilled"
    />
    <settings-sidebar-item
      name="Appearance"
      :icon="IconAspectRatioFilled"
    />
    <settings-sidebar-item
      name="Updates"
      :icon="IconCalendarFilled"
    />
    <div class="section-header">
      Help & Community
    </div>
    <nuxt-link to="/onboarding">
      <settings-sidebar-item
        :icon="IconFlag3Filled"
        name="Quick Start"
      />
    </nuxt-link>
    <settings-sidebar-item
      name="Documentation"
      :icon="IconBookFilled"
      external
    />
    <settings-sidebar-item
      name="Join our Discord"
      :icon="IconBrandDiscordFilled"
      external
    />
    <settings-sidebar-item
      name="Legal"
      :icon="IconInfoOctagonFilled"
      external
    />
    <!-- <settings-sidebar-item name="Invite Friends" /> -->
  </div>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'
.section-header
  @apply text-lg text-text-tertiary font-medium pt-8 pb-1
.account
  @apply flex gap-3 items-center my-1 p-2 hover:bg-accent-neutral rounded-lg -mx-2 cursor-pointer
</style>
