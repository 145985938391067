interface State {
  navigatedFrom: string
}
let settingsState: Ref<State>

function exitSettings() {
  navigateTo(settingsState.value.navigatedFrom)
}

export default function () {
  settingsState = useState("settings-state", () => ({
    navigatedFrom: "/home",
  }))
  return {
    exitSettings,
  }
}
