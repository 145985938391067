<script setup lang="ts">
import { isDesktop } from "~/utils/platform"

const { onAppMounted } = useNavigation()
const { exitSettings } = useSettings()

useHead({
  bodyAttrs: {
    class: "bg-bg-secondary text-base t-[#{$global-t-default}] overflow-hidden",
  },
  titleTemplate: (titleChunk) => {
    return `${titleChunk} | Poly: Intelligent Cloud Storage`
  },
})

onMounted(async () => onAppMounted(true))
</script>

<template>
  <native-drag-region />
  <nav-pill-view
    class="pointer-events-auto fixed right-3 top-3 cursor-pointer bg-$global-red-primary!"
    @click="exitSettings()"
  >
    <div class="flex items-center gap-2 px-2 font-medium">
      <span class="rounded-sm bg-accent-weakest px-1 py-0.5 text-2xs font-medium">ESC</span>
      <span>Exit Settings</span>
    </div>
  </nav-pill-view>
  <div
    class="flex"
    :class="{
      'bg-background-browser!': !isDesktop(),
      'bg-background-window!': isDesktop(),
    }"
  >
    <settings-sidebar-view />
    <div
      class="h-screen w-full pt-14"
    >
      <slot />
    </div>
  </div>
</template>
